import { accountingActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { AccountingServices } from '../../shared/services/AccountingServices';

export const loadBalanceSheet = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await AccountingServices.getBalanceSheet();
      dispatch(accountingActions.setBalanceSheet(result.data));
    });
  };
};
