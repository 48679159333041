import { uiActions, contractActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { ContractServices } from '../../shared/services/ContractServices';
import {
  CreateContractLineItemDTO,
  SaveContractTaskDTO,
  TerminateDTO,
} from '../../shared/services/models/ContractModel';

export const loadContract = (contractId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const contractResult = await ContractServices.getContractById(contractId);
      dispatch(contractActions.setContract(contractResult.data));
    });
  };
};

export const loadContractItems = (contractId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const contractResult = await ContractServices.getContractItems(
        contractId
      );
      dispatch(contractActions.setContractId(contractId));
      dispatch(contractActions.setContractItems(contractResult.data));
    });
  };
};

export const loadContractTasks = (contractId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const contractResult = await ContractServices.getContractTasks(
        contractId
      );
      dispatch(contractActions.setContractTasks(contractResult.data));
    });
  };
};

export const terminateContract = (
  contractId: number,
  request: TerminateDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.terminateContract(contractId, request);
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract terminated successfully',
        })
      );
    });
  };
};

export const createContractItem = (
  contractId: number,
  model: CreateContractLineItemDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.createContractItem(contractId, model);
      dispatch(contractActions.loadContractItems(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract item created successfully',
        })
      );
    });
  };
};
export const terminateContractItem = (
  contractId: number,
  contractItemId: number,
  request: TerminateDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.terminateContractItem(
        contractId,
        contractItemId,
        request
      );
      dispatch(contractActions.loadContractItems(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract item terminated successfully',
        })
      );
    });
  };
};

export const saveContractTask = (
  contractId: number,
  taskId: number,
  model: SaveContractTaskDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (taskId) {
        await ContractServices.updateContractTask(contractId, taskId, model);
      } else {
        await ContractServices.createContractTask(contractId, model);
      }
      dispatch(contractActions.loadContractTasks(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract task created successfully',
        })
      );
    });
  };
};

export const updateContractTitle = (contractId: number, title: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.updateContractTitle(contractId, title);
      dispatch(contractActions.loadContract(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract task updated successfully',
        })
      );
    });
  };
};

export const updateContactName = (contractId: number, title: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.updateContactName(contractId, title);
      dispatch(contractActions.loadContract(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract task updated successfully',
        })
      );
    });
  };
};

export const updateContactEmail = (contractId: number, title: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.updateContactEmail(contractId, title);
      dispatch(contractActions.loadContract(contractId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract task updated successfully',
        })
      );
    });
  };
};
