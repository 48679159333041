import { PaginationFilterDTO } from './PaginationModel';

export enum TimeoffStatusEnum {
  Submitted = 15002,
  Approved = 15003,
  Rejected = 15004,
}

export interface GetTimeoffsByFilterDTO extends PaginationFilterDTO {
  startDate?: string;
  endDate?: string;
  statusId?: number;
  organizationId?: number;
}

export interface TimeoffDTO {
  timeoffId: number;
  userId: number;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  startDate: string;
  endDate: string;
  type: string;
  timeoffStatusId: number;

  completedUserId?: number;
  completedDate?: string;
  submittedDate?: string;

  daysOff?: Array<string>;
}

export interface SaveTimeoffDTO {
  timeoffId: number;
  startDate: string;
  endDate: string;
  type: string;
}

export interface UserTimeoffSummaryDTO {
  days: number;
  totalDays: number;
  type: string;
}

export interface PublicHolidayDTO {
  date: string;
  holiday: string;
  description: string;
  countryCode: string;
}
