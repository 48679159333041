import { createSlice } from '@reduxjs/toolkit';

import {
  searchBills,
  createBill,
  approveBill,
  payBill,
  downloadFile,
  rejectBill,
} from './bills-actions';
import { pageInitialState, pageReducerMethods } from '../pagination';

import { ContextActions } from '..';
import {
  BillDTO,
  BillStatusEnum,
} from '../../shared/services/models/BillsModel';

export const billsSlice = createSlice({
  name: 'bills',
  initialState: {
    ...pageInitialState<BillDTO>(),
    lastFilter: null,

    sortColumn: 'submittedDate',
    sortDirection: 1,

    statusId: BillStatusEnum.Submitted,

    showCreateBill: false,
    selectedBill: null as BillDTO | null,
    docUrl: '',
    showDoc: false,
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, billsSlice.getInitialState());
    },
    setFilterStatus(state, action) {
      state.statusId = action.payload;
    },
    setShowCreateBill(state, action) {
      state.showCreateBill = action.payload;
    },
    setSelectedBill(state, action) {
      state.selectedBill = action.payload;
    },
    setDocUrl(state, action) {
      state.docUrl = action.payload;
      state.showDoc = !!state.docUrl;
    },
    setShowDoc(state, action) {
      state.showDoc = action.payload;
    },
  },
});

export const billsActions = {
  ...billsSlice.actions,
  searchBills,
  createBill,
  approveBill,
  payBill,
  downloadFile,
  rejectBill,
};

export type BillsContextType = ReturnType<typeof billsSlice.getInitialState> &
  Partial<ContextActions<typeof billsActions>>;

export const BillsContextValue = billsSlice.getInitialState();
