import { createSlice } from '@reduxjs/toolkit';
import { ContextActions } from '..';
import { InvoiceInfo } from '../../shared/services/models/InvoiceModel';

import { loadInvoice } from './invoice-actions';

export interface InvoiceReportSubItem {
  taskId: number;
  task: string;
  hours: number;
  amount: number;
}
export interface InvoiceReportItem {
  userName: string;
  lines: Array<InvoiceReportSubItem>;
}

interface InvoiceDTO extends InvoiceInfo {
  items: Array<any>;
  totalAmount: number;
  totalHours: number;
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    invoiceId: 0,
    invoice: null as InvoiceDTO | null,
  },
  reducers: {
    setInvoice(state, action) {
      const invoice = action.payload as InvoiceInfo;
      if (invoice) {
        state.invoiceId = invoice.invoiceId;
        const result: Array<InvoiceReportItem> = [];
        invoice.items.forEach((item) => {
          let invoiceitem = result.find(
            (x) => x.userName === item.userFullName
          );
          if (!invoiceitem) {
            invoiceitem = { userName: item.userFullName, lines: [] };
            result.push(invoiceitem);
          }
          let sItem = invoiceitem.lines.find((x) => x.taskId === item.taskId);
          if (!sItem) {
            sItem = {
              taskId: item.taskId,
              task: item.userTask,
              hours: 0,
              amount: 0,
            };
            invoiceitem.lines.push(sItem);
          }
          sItem.hours += item.hours;
          sItem.amount += item.paidAmount ?? item.billingRate * item.hours;
        });

        let totalAmount = 0;
        let totalHours = 0;
        invoice.items.forEach((i) => {
          totalAmount += i.paidAmount ?? i.hours * i.billingRate;
          totalHours += i.hours;
        });

        state.invoice = {
          ...invoice,
          totalAmount,
          totalHours,
          items: result,
        };
      }
    },
  },
});

export const invoiceActions = {
  ...invoiceSlice.actions,
  loadInvoice,
};

export type InvoiceContextType = ReturnType<
  typeof invoiceSlice.getInitialState
> &
  Partial<ContextActions<typeof invoiceActions>>;

export const InvoiceContextValue = invoiceSlice.getInitialState();
