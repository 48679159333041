import * as React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ProfileIcon from '@mui/icons-material/PermIdentitySharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';

import RolesAndPermIcon from '@mui/icons-material/EngineeringOutlined';
import OrganizationIcon from '@mui/icons-material/BusinessOutlined';
import MyOrganizationIcon from '@mui/icons-material/StoreOutlined';
import ApprovalIcon from '@mui/icons-material/ApprovalOutlined';

import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router';
import { RippleBadge } from '../transitions';
import { authActions } from '../../store';

import { UserInfo } from '../../shared/services/models/AuthModel';
import { useUser } from '../../hooks/useUser';
import { useAppDispatch, useCurrentUser } from '../../hooks';
import { OrganizationSelector } from './OrgSelect';
import { useOrgs } from '../../contexts/OrgContext';
import { useNotificationHub } from '../../contexts/NotificationHubContext';
import { AccountBalanceOutlined } from '@mui/icons-material';

function UserAvatar({ user }: { user: UserInfo }) {
  const { isConnected } = useNotificationHub();
  return isConnected ? (
    <RippleBadge
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant='dot'
    >
      <Avatar alt={user.firstName} src={`${user.avatarUrl}`}>
        {user.firstName[0]}
        {user.lastName[0]}
      </Avatar>
    </RippleBadge>
  ) : (
    <Avatar alt={user.firstName} src={`${user.avatarUrl}`}>
      {user.firstName[0]}
      {user.lastName[0]}
    </Avatar>
  );
}

function UserProfileItem({ user }: { user: UserInfo }) {
  const { selectedOrganization } = useOrgs();

  return (
    <Box display={'flex'} alignItems={'center'} sx={{ gridGap: 8 }}>
      <UserAvatar user={user} />
      <Box>
        <Typography variant='subtitle2'>
          {user.firstName} {user.lastName}
        </Typography>
        <Typography variant='caption'>{selectedOrganization?.name}</Typography>
      </Box>
    </Box>
  );
}

export function UserProfileMenu() {
  const dispatch = useAppDispatch();
  const user = useUser();
  const { locked, showOrganizations } = useOrgs();

  const currentUser = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const goToProfile = () => {
    navigate('/profile');
    setAnchorEl(null);
  };

  const doAllLogout = () => {
    dispatch(authActions.logout());
    window.location.href = '/login';
  };

  const goToOrganizations = () => {
    navigate('/organizations');
    setAnchorEl(null);
  };

  const goToRolesAndPermissions = () => {
    navigate('/rolesandperm');
    setAnchorEl(null);
  };

  const goToApprovals = () => {
    navigate('/approvals');
    setAnchorEl(null);
  };

  const goToMyOrganization = () => {
    navigate('/myorganization');
    setAnchorEl(null);
  };

  const goToBalanceSheet = () => {
    navigate('/accounting');
    setAnchorEl(null);
  };

  return user ? (
    <Box display='flex' alignItems={'center'}>
      <OrganizationSelector />

      <IconButton onClick={handleClick}>
        <UserAvatar user={user} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            if (!locked) {
              showOrganizations!();
              setAnchorEl(null);
            }
          }}
        >
          <ListItemText>
            <UserProfileItem user={user} />
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={goToProfile} className='mnu-profile'>
          <ListItemIcon>
            <ProfileIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        {currentUser.canApproveTimesheets && <Divider />}
        {currentUser.canApproveTimesheets && (
          <MenuItem onClick={goToApprovals} className='mnu-approvals'>
            <ListItemIcon>
              <ApprovalIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Approvals</ListItemText>
          </MenuItem>
        )}
        {currentUser.ORGADM && (
          <MenuItem onClick={goToBalanceSheet} className='mnu-org'>
            <ListItemIcon>
              <AccountBalanceOutlined fontSize='small' />
            </ListItemIcon>
            <ListItemText>Balance sheet</ListItemText>
          </MenuItem>
        )}
        {currentUser.ORGADM && (
          <MenuItem onClick={goToMyOrganization} className='mnu-org'>
            <ListItemIcon>
              <MyOrganizationIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>My Organization</ListItemText>
          </MenuItem>
        )}

        {(currentUser.ORGADM || currentUser.canApproveTimesheets) && (
          <Divider />
        )}

        {currentUser.canViewOrganization && (
          <MenuItem onClick={goToOrganizations} className='mnu-orgs'>
            <ListItemIcon>
              <OrganizationIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Manage Organizations</ListItemText>
          </MenuItem>
        )}

        {currentUser.canViewOrganization && (
          <MenuItem onClick={goToRolesAndPermissions} className='mnu-roles'>
            <ListItemIcon>
              <RolesAndPermIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Roles and Profiles</ListItemText>
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={doAllLogout} className='mnu-logout'>
          <ListItemIcon>
            <LogoutSharpIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <></>
  );
}
