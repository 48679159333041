import { uiActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { profileActions } from './profile-slice';
import { ProfileServices } from '../../shared/services/ProfileServices';
import { ProfilePictureServices } from '../../shared/services/ProfilePictureServices';
import { SaveUserDTO } from '../../shared/services/models/UserModel';

export const initialize = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await ProfileServices.getMyProfile();
      dispatch(profileActions.setProfile(result.data));
      const docResult = await ProfileServices.getMyProfileDocumentAsync();
      dispatch(profileActions.setProfileDocuments(docResult.data));
    });
  };
};

export const saveProfile = (profile: SaveUserDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await ProfileServices.saveMyProfileAsync(profile);
      dispatch(profileActions.setProfile(result.data));

      dispatch(profileActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Profile was successfully saved',
        })
      );
    });
  };
};

export const saveProfilePic = (profilePic: { picData: string }) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ProfilePictureServices.saveMyProfilePicAsync(profilePic);
      dispatch(profileActions.setShowProfilePicEdit(false));
      dispatch(profileActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Profile was successfully saved',
        })
      );
    });
  };
};

export const saveAvatarPic = (profilePic: { picData: string }) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ProfilePictureServices.saveMyAvatarPicAsync(profilePic);
      dispatch(profileActions.setShowAvatarPicEdit(false));
      dispatch(profileActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Profile was successfully saved',
        })
      );
    });
  };
};

export const saveDocument = (model: FormData) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ProfileServices.saveMyProfileDocumentAsync(model);
      dispatch(profileActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Profile was successfully saved',
        })
      );
    });
  };
};

export const downloadFile = (model: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const path = await ProfileServices.getMyProfileDocumentUrlAsync(model);
      dispatch(profileActions.setDocUrl(path.data));
    });
  };
};
