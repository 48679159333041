import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { authActions } from '../../store';
import { LoginGoogle } from './LoginGoogle';

export function LoginForm() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = useCallback(
    (e: any) => {
      dispatch(authActions.login(userName, password));
      e.preventDefault();
    },
    [userName, password, dispatch]
  );

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        rowGap={1}
        p={1}
        textAlign={'center'}
      >
        <Typography variant={'h6'} color={'primary'}>
          Hi, Welcome back
        </Typography>
        <Typography variant={'body2'} color={'grey.500'}>
          Sign in with Username or Email
        </Typography>
      </Box>

      <form noValidate onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Grid container spacing={3} sx={{ maxWidth: ['inherit', 400] }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name='userName'
                label='Username/Email'
                required
                variant='filled'
                value={userName}
                onChange={(e) => setUserName(`${e.target.value}`)}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='filled' fullWidth required>
                <InputLabel>Password</InputLabel>
                <FilledInput
                  fullWidth
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  disabled={loading}
                  onChange={(e) => setPassword(`${e.target.value}`)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gridGap: (theme) => theme.spacing(1),
                }}
              >
                <Button
                  disabled={loading}
                  variant='contained'
                  fullWidth
                  color='primary'
                  type='submit'
                >
                  Sign in
                </Button>
                <Button variant='text' fullWidth>
                  <Link
                    href='/forgot'
                    sx={{ textDecoration: 'none' }}
                    className='btn-forgot-pwd'
                  >
                    Forgot Password?
                  </Link>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <LoginGoogle />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
