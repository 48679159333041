import { vendorBillsActions, uiActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { shallowEqual } from 'react-redux';

import {
  VendorBillDTO,
  GetVendorBillsByFilterDTO,
} from '../../shared/services/models/VendorBillsModel';

import { VendorBillServices } from '../../shared/services/VendorBillServices';
import {
  ApproveBillDTO,
  PayBillDTO,
} from '../../shared/services/models/BillsModel';

export const loadVendors = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        vendorBills: { vendors },
      } = getState();
      if (!vendors || vendors.length === 0) {
        dispatch(vendorBillsActions.setVendors([]));
        const result = await VendorBillServices.getVendorsByFilterAsync({
          pageNumber: 0,
          pageSize: 10,
        });
        dispatch(vendorBillsActions.setVendors(result.data.items || []));
      }
    });
  };
};

export const searchBills = (filter: GetVendorBillsByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        vendorBills: { items, lastFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !lastFilter ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(vendorBillsActions.setItems([]));
        dispatch(vendorBillsActions.setLastFilter(filter));
        const result = await VendorBillServices.getVendorBillsByFilterAsync(
          filter
        );
        dispatch(vendorBillsActions.setItems(result.data.items || []));
        dispatch(vendorBillsActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const createBill = (vendorId: number, model: FormData) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await VendorBillServices.createBillAsync(vendorId, model);
      dispatch(vendorBillsActions.doForceRefresh());
      dispatch(vendorBillsActions.setSelectedBill(null));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully created',
        })
      );
    });
  };
};

export const approveBill = (
  vendorId: number,
  billId: number,
  model: ApproveBillDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await VendorBillServices.approveBillAsync(vendorId, billId, model);
      dispatch(vendorBillsActions.doForceRefresh());
      dispatch(vendorBillsActions.setSelectedBill(null));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully approved',
        })
      );
    });
  };
};

export const payBill = (
  vendorId: number,
  billId: number,
  model: PayBillDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await VendorBillServices.payBillAsync(vendorId, billId, model);
      dispatch(vendorBillsActions.doForceRefresh());
      dispatch(vendorBillsActions.setSelectedBill(null));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully paid',
        })
      );
    });
  };
};

export const downloadFile = (model: VendorBillDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const path = await VendorBillServices.getDocumentUrlAsync(
        model.vendorId,
        model.docId
      );
      dispatch(vendorBillsActions.setDocUrl(path.data));
    });
  };
};

export const rejectBill = (vendorId: number, billId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await VendorBillServices.rejectBillAsync(vendorId, billId);
      dispatch(vendorBillsActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully rejected',
        })
      );
    });
  };
};
