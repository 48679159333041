import { shallowEqual } from 'react-redux';
import { GetUsersByFilterDTO } from '../../shared/services/models/UserModel';
import { UserServices } from '../../shared/services/UserServices';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { uiActions } from '..';
import { usersActions } from './users-slice';

export const searchUsers = (filter: GetUsersByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        users: { items, lastFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !lastFilter ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(usersActions.setLastFilter(filter));
        const result = await UserServices.getUsersByFilterAsync(filter);
        const users =
          result.data.items.map((x) => ({
            ...x,
            id: x.userId,
          })) || [];

        dispatch(usersActions.setItems(users));

        dispatch(usersActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const terminateUser = (userId: number, termDate: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.terminateUserAsync(userId, { endDate: termDate });
      dispatch(usersActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User termination was successful',
        })
      );
    });
  };
};

export const assignUserManager = (userId: number, managerUserId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.assignUserManagerAsync(userId, managerUserId);
      dispatch(usersActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User manager was successfully assigned',
        })
      );
    });
  };
};
