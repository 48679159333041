import { InvoiceServices } from '../../shared/services/InvoiceServices';
import { invoiceActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';

export const loadInvoice = (invoiceId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await InvoiceServices.getInvoiceById(invoiceId);
      dispatch(invoiceActions.setInvoice(result.data));
    });
  };
};
