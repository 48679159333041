import { orgsActions, uiActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { UserOrganizationServices } from '../../shared/services/UserOrganizationServices';
import { OrganizationDTO } from '../../shared/services/models/OrganizationModel';

export const loadOrgs = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        orgs: { organizations },
      } = getState();
      if (organizations.length === 0) {
        const result = await UserOrganizationServices.getMyOrganizationsAsync();
        dispatch(orgsActions.setOrganizations(result.data));

        const orgId = localStorage.getItem('OrganizationId');
        if (orgId) {
          const org = result.data.find(
            (x) => x.organizationId === parseInt(orgId)
          );
          dispatch(orgsActions.selectOrganization(org));
          const resultHolidays =
            await UserOrganizationServices.getPublicHolidaysAsync();
          dispatch(orgsActions.setPublicHolidays(resultHolidays.data));
        }
      }
    });
  };
};

export const saveOrganization = (organization: OrganizationDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (organization.organizationId) {
        await UserOrganizationServices.updateOrganizationAsync({
          ...organization,
        });
      }
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Organization was successfully saved',
        })
      );
    });
  };
};
