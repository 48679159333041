import { createSlice } from '@reduxjs/toolkit';
import { searchUsers, terminateUser, assignUserManager } from './users-actions';
import { ContextActions } from '..';
import { UserInfoDTO } from '../../shared/services/models/UserModel';
import { UserStatusEnum } from '../../shared/Domain';
import { pageInitialState, pageReducerMethods } from '../pagination';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    ...pageInitialState<UserInfoDTO>(),
    lastFilter: null,
    sortColumn: 'FirstName',
    filter: UserStatusEnum.Active,
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, usersSlice.getInitialState());
    },
    setFilter(state, action) {
      state.filter = action.payload;
      state.pageNumber = 0;
    },
  },
});

export const usersActions = {
  ...usersSlice.actions,
  searchUsers,

  terminateUser,
  assignUserManager,
};

export type UsersContextType = ReturnType<typeof usersSlice.getInitialState> &
  Partial<ContextActions<typeof usersActions>>;

export const UsersContextValue = usersSlice.getInitialState();
