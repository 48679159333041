import { createSlice } from '@reduxjs/toolkit';

import {
  initialize,
  search,
  saveTimeoff,
  approve,
  reject,
} from './timeoffs-actions';
import { pageInitialState, pageReducerMethods } from '../pagination';
import { dateToString, today } from '../../shared/DateUtil';
import { ContextActions } from '..';
import {
  TimeoffDTO,
  TimeoffStatusEnum,
  UserTimeoffSummaryDTO,
  PublicHolidayDTO,
} from '../../shared/services/models/TimeoffModel';

export const timeoffsSlice = createSlice({
  name: 'timeoffs',
  initialState: {
    ...pageInitialState<TimeoffDTO>(),
    lastFilter: null,

    sortColumn: 'submittedDate',
    sortDirection: 1,

    startDate: dateToString(new Date(today().getFullYear(), 0, 1)),
    endDate: dateToString(new Date(today().getFullYear(), 11, 31)),
    statusId: TimeoffStatusEnum.Submitted,

    selected: null as TimeoffDTO | null,

    summary: [] as Array<UserTimeoffSummaryDTO>,
    holidays: [] as Array<PublicHolidayDTO>,
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, timeoffsSlice.getInitialState());
    },
    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setFilterStatus(state, action) {
      state.statusId = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSummary(state, action) {
      state.summary = action.payload;
    },
    setPublicHolidays(state, action) {
      state.holidays = action.payload;
    },
  },
});

export const timeoffsActions = {
  ...timeoffsSlice.actions,
  search,
  saveTimeoff,
  approve,
  reject,
  initialize,
};

export type TimeoffsContextType = ReturnType<
  typeof timeoffsSlice.getInitialState
> &
  Partial<ContextActions<typeof timeoffsActions>>;

export const TimeoffsContextValue = timeoffsSlice.getInitialState();
