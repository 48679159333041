import { createTheme } from '@mui/material/styles';

const borderRadius = 8;

export const customTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: 'rgb(71, 145, 219)',
      main: '#1976d2',
      dark: '#0052cc',
      contrastText: '#fff',
    },
    secondary: {
      contrastText: '#1976d2',
      dark: '#dee7fd',
      light: '#f3f6fe',
      main: '#edf2ff',
    },
    action: {
      activatedOpacity: 0.12,
      active: '#757575',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.06)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
  },
  shape: {
    borderRadius,
  },
  spacing: 8,
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          textTransform: 'none',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        }),
        scrollButtons: {
          borderRadius,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: borderRadius / 2,
          '& > .MuiInputLabel-root': {
            marginLeft: ownerState.InputProps?.startAdornment
              ? theme.spacing(4)
              : 'auto',
          },
        }),
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: borderRadius,
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
          },
        }),
        error: ({ theme }) => ({
          border: `2px solid ${theme.palette.error.main}`,
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-asterisk': {
            display: 'none',
          },
          ':not(&.Mui-required)': {
            marginLeft: 0,
            '&::after': {
              content: '" (Optional)"',
            },
            '& + .MuiOutlinedInput-root': {
              '& fieldset span::after': {
                content: '" (Optional)"',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow:
            'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        },
      },
    },
  },
});
