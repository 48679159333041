import { Box, Tabs, Tab } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useCurrentUser } from '../../hooks/useCurrentUser';
import { ButtonMenu } from '../button-menu';
import MenuIcon from '@mui/icons-material/Menu';

export function MainMenu() {
  const navigate = useNavigate();

  const currentUser = useCurrentUser();

  const location = useLocation();

  const menuItems = useMemo(() => {
    const h = (a: string) => {
      if (a === '/') {
        if (location.pathname === '/') return true;
      } else {
        return location.pathname.startsWith(a);
      }
      return false;
    };
    const arr = [
      {
        title: 'Dashboard',
        url: '/',
        selected: false,
      },
      {
        title: 'Timesheets',
        url: '/timesheets',
        selected: false,
      },
      {
        title: 'Bills',
        url: '/bills',
        selected: false,
      },
      {
        title: 'Timeoffs',
        url: '/timeoffs',
        selected: false,
      },
    ];

    if (currentUser.canViewClient) {
      arr.push({
        title: 'Clients',
        url: '/clients',
        selected: false,
      });
    }

    if (currentUser.canViewUser) {
      arr.push({
        title: 'Users',
        url: '/users',
        selected: false,
      });
    }

    if (currentUser.canViewInvoice) {
      arr.push({
        title: 'Invoices',
        url: '/invoices',
        selected: false,
      });
    }
    arr.forEach((a) => (a.selected = h(a.url)));
    return arr;
  }, [
    currentUser.canViewClient,
    currentUser.canViewInvoice,
    currentUser.canViewUser,
    location.pathname,
  ]);

  const onClick = useCallback(
    (m: any) => {
      navigate(m.url);
    },
    [navigate]
  );

  const value = menuItems.find((x) => x.selected)?.title ?? 'Dashboard';
  return (
    <>
      <Box sx={{ display: ['none', 'none', 'block'] }}>
        <Tabs value={value} sx={{ backgroundColor: 'transparent' }}>
          {menuItems.map((m: any) => (
            <Tab
              key={m.title}
              value={m.title}
              onClick={() => onClick(m)}
              label={m.title}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ display: ['flex', 'flex', 'none'] }} flexDirection={'column'}>
        <ButtonMenu onClick={onClick} items={menuItems} title={<MenuIcon />} />
      </Box>
    </>
  );
}
