import { shallowEqual } from 'react-redux';
import { clientsActions, uiActions } from '..';
import { ClientServices } from '../../shared/services/ClientServices';
import {
  ClientDTO,
  ClientStatusEnum,
  SaveClientDTO,
} from '../../shared/services/models/ClientModel';
import { DISPATCH, executeAction, GET_STATE } from '../actions';

export const initializeClients = (filter: any) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        clients: { allItems, lastFilter },
      } = getState();
      if (
        !allItems ||
        allItems.length === 0 ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(clientsActions.setLastFilter(filter));
        const result = await ClientServices.getClients();
        dispatch(clientsActions.setItems(result.data || []));
      }
    });
  };
};

export const createClient = (client: SaveClientDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await ClientServices.createClientAsync(client);
      dispatch(
        clientsActions.addClient({
          clientId: result.data,
          ...client,
          statusId: ClientStatusEnum.InActive,
        })
      );
      dispatch(clientsActions.setFilter(0));
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'Client was successfully created',
        })
      );
    });
  };
};

export const updateClient = (clientId: number, client: SaveClientDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ClientServices.updateClientAsync(clientId, client);
      dispatch(
        clientsActions.saveClient({
          clientId,
          ...client,
        })
      );
      dispatch(clientsActions.setFilter(0));
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'Client was successfully updated',
        })
      );
    });
  };
};

export const toggleStatus = (client: ClientDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    const {
      clients: { filter },
    } = getState();
    await executeAction(dispatch, async () => {
      if (client.statusId === ClientStatusEnum.Active) {
        await ClientServices.deActivateClientAsync(client.clientId);
        dispatch(
          clientsActions.updateStatus({
            clientId: client.clientId,
            statusId: ClientStatusEnum.InActive,
          })
        );
      } else if (client.statusId === ClientStatusEnum.InActive) {
        await ClientServices.activateClientAsync(client.clientId);
        dispatch(
          clientsActions.updateStatus({
            clientId: client.clientId,
            statusId: ClientStatusEnum.Active,
          })
        );
      }
      dispatch(clientsActions.setFilter(filter));
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: `Client was ${
            client.statusId === ClientStatusEnum.InActive
              ? 'activated'
              : 'terminated'
          } successfully`,
        })
      );
    });
  };
};
