import { InvoiceServices } from '../../shared/services/InvoiceServices';
import { uiActions, invoicesActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import {
  CompleteInvoiceDTO,
  GetInvoicesByFilterDTO,
  InvoiceStatusEnum,
} from '../../shared/services/models/InvoiceModel';
import { shallowEqual } from 'react-redux';
import { ClientServices } from '../../shared/services/ClientServices';

export const loadClients = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        invoices: { clients },
      } = getState();
      if (clients.length === 0) {
        const result = await ClientServices.getClients();
        dispatch(invoicesActions.setClients(result.data));
      }
    });
  };
};

export const loadInvoiceSummary = (filter: GetInvoicesByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        invoices: { lastFilter },
      } = getState();
      if (!lastFilter || !shallowEqual(lastFilter, filter)) {
        const result = await InvoiceServices.getInvoicesSummaryByFilter(filter);
        dispatch(invoicesActions.setSummary(result.data));
      }
    });
  };
};

export const searchInvoices = (filter: GetInvoicesByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        invoices: { items, lastFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !lastFilter ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(invoicesActions.setLastFilter(filter));
        const result = await InvoiceServices.getInvoicesByFilter(filter);
        dispatch(invoicesActions.setItems(result.data.items || []));
        dispatch(invoicesActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const completeInvoice = (request: CompleteInvoiceDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await InvoiceServices.completeInvoice(request);
      dispatch(
        invoicesActions.setInvoiceStatus({
          invoiceId: request.invoiceId,
          statusId: InvoiceStatusEnum.Paid,
        })
      );
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice Completed successfully',
        })
      );
    });
  };
};
