import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth/auth-slice';
import { clientSlice } from './clients/client-slice';
import { clientsSlice } from './clients/clients-slice';
import { contractSlice } from './contract/contract-slice';
import { invoiceSlice } from './invoices/invoice-slice';
import { invoicesSlice } from './invoices/invoices-slice';
import { timesheetsSlice } from './timesheets/timesheets-slice';
import { usersSlice } from './users/users-slice';
import { orgsSlice } from './orgs/orgs-slice';
import { uiSlice } from './ui/ui-slice';
import { dashboardSlice } from './dashboard/dashboard-slice';
import { organizationsSlice } from './organizations/organizations-slice';
import { rolesAndPermissionsSlice } from './roles-permissions/roles-permissions-slice';
import { profileSlice } from './profile/profile-slice';
import { approvalsSlice } from './approvals/approvals-slice';
import { userSlice } from './users/user-slice';
import { billsSlice } from './bills/bills-slice';
import { timeoffsSlice } from './timeoffs/timeoffs-slice';
import { notificationsSlice } from './notifications/notifications-slice';
import { vendorBillsSlice } from './vedor-bills/vendor-bills-slice';
import { billsSummarySlice } from './bills/bills-summary-slice';
import { accountingSlice } from './accounting/accounting-slice';

export type ContextActions<T> = {
  [Property in keyof T]: (payload?: any) => void;
};

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    approvals: approvalsSlice.reducer,
    bills: billsSlice.reducer,
    client: clientSlice.reducer,
    clients: clientsSlice.reducer,
    contract: contractSlice.reducer,
    invoice: invoiceSlice.reducer,
    invoices: invoicesSlice.reducer,
    timesheets: timesheetsSlice.reducer,
    dashboard: dashboardSlice.reducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
    orgs: orgsSlice.reducer,
    organizations: organizationsSlice.reducer,
    rolesAndPermissions: rolesAndPermissionsSlice.reducer,
    profile: profileSlice.reducer,
    auth: authSlice.reducer,
    timeoffs: timeoffsSlice.reducer,
    notifications: notificationsSlice.reducer,
    vendorBills: vendorBillsSlice.reducer,
    billsSummary: billsSummarySlice.reducer,
    accounting: accountingSlice.reducer,
  },
});

export default store;

export { authActions } from './auth/auth-slice';
export { approvalsActions } from './approvals/approvals-slice';
export { accountingActions } from './accounting/accounting-slice';
export { billsActions } from './bills/bills-slice';
export { clientActions } from './clients/client-slice';
export { clientsActions } from './clients/clients-slice';
export { contractActions } from './contract/contract-slice';
export { invoiceActions } from './invoices/invoice-slice';
export { invoicesActions } from './invoices/invoices-slice';
export { timesheetsActions } from './timesheets/timesheets-slice';
export { userActions } from './users/user-slice';
export { usersActions } from './users/users-slice';
export { orgsActions } from './orgs/orgs-slice';
export { organizationsActions } from './organizations/organizations-slice';
export { dashboardActions } from './dashboard/dashboard-slice';
export { rolesAndPermissionsActions } from './roles-permissions/roles-permissions-slice';
export { profileActions } from './profile/profile-slice';
export { uiActions } from './ui/ui-slice';
export { timeoffsActions } from './timeoffs/timeoffs-slice';
export { notificationsActions } from './notifications/notifications-slice';
export { vendorBillsActions } from './vedor-bills/vendor-bills-slice';
export { billsSummaryActions } from './bills/bills-summary-slice';

export type { WeekItem } from './timesheets/timesheets-slice';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
