import { TimesheetTaskDTO } from './TimesheetModel';

export enum ClientStatusEnum {
  All = 0,
  Active = 12002,
  InActive = 12003,
}

export interface ClientDTO {
  clientId: number;
  clientName: string;
  clientDescription: string;
  logoUrl: string;
  statusId: number;
  dueDatePeriod?: number;
}

export interface ClientWithTasksDTO {
  clientId: number;
  clientName: string;
  clientDescription: string;
  logoUrl: string;
  statusId: number;

  contractId: number;
  contractName: string;
  beginDate: string;
  endDate: string;

  tasks: Array<TimesheetTaskDTO>;
}

export interface SaveClientDTO {
  clientName: string;
  clientDescription: string;
  logoUrl: string;
  currency: string;
  dueDatePeriod?: number;
}

export interface SaveClientPicDTO {
  clientId: number;
  picData: string;
}
