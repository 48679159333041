import { ApiServices } from './ApiServices';
import { BalanceSheetDTO } from './models/AccountingModel';

export class AccountingServices {
  static getBalanceSheet() {
    return ApiServices.get<BalanceSheetDTO[]>(
      `${process.env.REACT_APP_ACC_API}/api/accounting`
    );
  }
}
