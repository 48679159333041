import { ApiServices } from './ApiServices';
import { ContractDTO } from './models/ContractModel';
import {
  SaveUserTaskDTO,
  TimesheetTaskDTO,
  TimesheetEntryDTO,
  SubmitTimesheetsDTO,
} from './models/TimesheetModel';

export class TimesheetServices {
  static getMyTasksAsync() {
    return ApiServices.get<Array<TimesheetTaskDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/timesheets/tasks`
    );
  }

  static getMyContractsAsync() {
    return ApiServices.get<Array<ContractDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/timesheets/contracts`
    );
  }

  static getTimesheetForDateAsync(
    startDate: string,
    endDate: string,
    searchText: string
  ) {
    return ApiServices.get<Array<TimesheetEntryDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/timesheets?startDate=${startDate}&endDate=${endDate}&searchText=${searchText}`
    );
  }

  static saveTimesheet(request: SaveUserTaskDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/timesheets/tasks`,
      { request }
    );
  }

  static submitTimesheetsAsync(model: SubmitTimesheetsDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/timesheetsapproval/submit`,
      model
    );
  }
}
