import { Box } from '@mui/material';

export function AnimatedInvoiceNotFound() {
  return (
    <Box
      display={'flex'}
      rowGap={2}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <div>Invoice Not Found</div>
    </Box>
  );
}
