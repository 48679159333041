export enum UserStatusEnum {
  Active = 11002,
  InActive = 11003,
}

export enum ClientStatusEnum {
  Active = 12002,
  InActive = 12003,
}

export enum InvoiceStatusEnum {
  UnPaid = 13002,
  Paid = 13003,
  Overdue = 13004,
}

export enum BillStatusEnum {
  Submitted = 14002,
  Approved = 14003,
}

export enum TimeoffStatusEnum {
  Submitted = 15002,
  Approved = 15003,
  Rejected = 15004,
}

export enum DocumentStatusEnum {
  Submitted = 16002,
  Verified = 16003,
  Rejected = 16004,
}

export enum UserTaskStatusEnum {
  Pending = 17002,
  Submitted = 17003,
  Approved = 17004,
}

const defaultDocs = [
  {
    id: 'dlic',
    displayValue: 'Drivers license',
    icon: '/images/docs/dlic.svg',
  },
  {
    id: 'passport',
    displayValue: 'Passport',
    icon: '/images/docs/passport.svg',
  },
  {
    id: 'ach',
    displayValue: 'ACH Form - signed',
    icon: '/images/docs/ach.svg',
  },
  {
    id: 'non-comp',
    displayValue: 'Non-Compete - signed',
    icon: '/images/docs/non-comp.svg',
  },
  {
    id: 'off-ltr',
    displayValue: 'Offer letter - signed',
    icon: '/images/docs/off-ltr.svg',
  },
  {
    id: 'join-ltr',
    displayValue: 'Joining letter - signed',
    icon: '/images/docs/join.svg',
  },
  {
    id: 'terms',
    displayValue: 'Terms of employment - signed',
    icon: '/images/docs/terms-emp.svg',
  },
  {
    id: 'bonus',
    displayValue: 'Bonus letter - signed',
    icon: '/images/docs/bonus.svg',
  },
  {
    id: 'hike',
    displayValue: 'Hike letter - signed',
    icon: '/images/docs/hike.svg',
  },
];

export const BillTypes = ['Invoice', 'Expense', 'Medical', 'Travel-Expense'];
export const TimeOffTypes = [
  {
    id: 'pto',
    displayValue: 'PTO - Paid Time Off',
    description: 'My paid time offs this year',
    color: 'success',
  },
  {
    id: 'sl',
    displayValue: 'SL - Sick Leave',
    description: 'My sick leaves this year',
    color: 'error',
  },
  {
    id: 'cmp',
    displayValue: 'CMP - Comp Off',
    description: 'If worked extra hours on clients demand',
    color: 'warning',
  },
];

export const AllCountries = [
  {
    id: 'US',
    displayValue: 'United States Of America',
    currencyCode: '$',
    taxLabel: 'SSN/EIN',
    taxLabel2: '',
    bankPrimary: 'Bank account number',
    bankSecondary: 'Bank routing number',
    documentTypes: [
      {
        id: 'w9',
        displayValue: 'W9 Form - signed',
        icon: '/images/docs/w9.svg',
      },
      ...defaultDocs,
    ],
  },
  {
    id: 'IN',
    displayValue: 'India',
    currencyCode: '₹',
    taxLabel: 'Aadhar card number',
    taxLabel2: 'PAN number',
    bankPrimary: 'Bank account number',
    bankSecondary: 'IFSC Code',
    documentTypes: [
      {
        id: 'aadhar',
        displayValue: 'AADHAR card - signed',
        icon: '/images/docs/aadhar.svg',
      },
      {
        id: 'pan',
        displayValue: 'PAN card - signed',
        icon: '/images/docs/pan.svg',
      },
      {
        id: 'w8ben',
        displayValue: 'W8-BEN form - signed',
        icon: '/images/docs/w8ben.svg',
      },
      ...defaultDocs,
    ],
  },
];

export const AllCurrencies = [
  {
    id: 'USD',
    displayValue: 'America Dollars',
    currencyCode: '$',
  },
  {
    id: 'INR',
    displayValue: 'Indian Rupee',
    currencyCode: '₹',
  },
];

const allDocs = [...defaultDocs];
AllCountries.forEach((c) => {
  c.documentTypes.forEach((dt) => {
    if (allDocs.findIndex((a) => a.id === dt.id)) {
      allDocs.push(dt);
    }
  });
});
export const AllDocs = allDocs;

export const paymentMethods = [
  {
    id: 60002,
    displayValue: 'ACH Transfer',
  },
  {
    id: 60003,
    displayValue: 'Wire Transfer',
  },
  {
    id: 60004,
    displayValue: 'Check',
  },
];
