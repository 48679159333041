import { createContext, useCallback, useContext, useEffect } from 'react';

import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks';

import {
  notificationsActions,
  NotificationsContextType,
  NotificationsContextValue,
} from '../../store/notifications/notifications-slice';

export const NotificationsContext = createContext<NotificationsContextType>(
  NotificationsContextValue
);

export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'NotificationsContext must be used within NotificationsContextProvider!'
    );
  }
  return context;
}

export function NotificationsContextProvider(props: any) {
  const dispatch = useAppDispatch();

  const { items, refreshCount } = useAppSelector(
    ({ notifications: { items, refreshCount } }) => ({
      items,
      refreshCount,
    }),
    shallowEqual
  );

  const deleteItem = useCallback(
    (filter: any) => {
      dispatch(notificationsActions.deleteItem(filter));
    },
    [dispatch]
  );

  const markRead = useCallback(
    (filter: any) => {
      dispatch(notificationsActions.markRead(filter));
    },
    [dispatch]
  );

  const doForceRefresh = useCallback(() => {
    dispatch(notificationsActions.doForceRefresh());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      notificationsActions.loadAll({
        refreshCount,
      })
    );
  }, [dispatch, refreshCount]);

  const providerValue: NotificationsContextType = {
    items,
    refreshCount,

    doForceRefresh,
    markRead,
    deleteItem,
  };

  return (
    <NotificationsContext.Provider value={providerValue}>
      {props.children}
    </NotificationsContext.Provider>
  );
}
