import { ApiServices } from './ApiServices';
import { SaveOrganizationDTO } from './models/OrganizationModel';
import { PublicHolidayDTO } from './models/TimeoffModel';
import { UserOrganizationDTO } from './models/UserOrganizationModel';

export class UserOrganizationServices {
  static getMyOrganizationsAsync() {
    return ApiServices.get<Array<UserOrganizationDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/userorganizations`
    );
  }

  static updateOrganizationAsync(request: SaveOrganizationDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/userorganizations`,
      {
        request,
      }
    );
  }

  static getPublicHolidaysAsync() {
    return ApiServices.get<Array<PublicHolidayDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/userorganizations/holidays`
    );
  }
}
