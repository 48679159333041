import { uiActions, clientActions, invoicesActions } from '..';
import { ClientServices } from '../../shared/services/ClientServices';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { ContractServices } from '../../shared/services/ContractServices';
import { InvoiceServices } from '../../shared/services/InvoiceServices';
import {
  CreateContractDTO,
  TerminateDTO,
} from '../../shared/services/models/ContractModel';
import { SaveClientPicDTO } from '../../shared/services/models/ClientModel';

export const loadClient = (clientId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        client: { client },
      } = getState();

      if (client?.clientId !== clientId) {
        const clientResult = await ClientServices.getClientById(clientId);
        dispatch(clientActions.setClient(clientResult.data));
        dispatch(clientActions.loadContracts(clientId));
      }
    });
  };
};

export const loadContracts = (clientId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const contractResult = await ClientServices.getContractsByClientId(
        clientId
      );
      dispatch(clientActions.setClientContracts(contractResult.data));
    });
  };
};

export const saveProfilePic = (profilePic: SaveClientPicDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ClientServices.saveProfilePicAsync(profilePic);
      dispatch(clientActions.setShowProfilePicEdit(false));
      //dispatch(clientActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Client was successfully saved',
        })
      );
    });
  };
};

export const createInvoice = (
  clientId: number,
  beginDate: string,
  endDate: string
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await InvoiceServices.createInvoice({ clientId, beginDate, endDate });

      dispatch(invoicesActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice Created successfully',
        })
      );
    });
  };
};

export const previewInvoice = (
  clientId: number,
  beginDate: string,
  endDate: string
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      var approvalResult = await InvoiceServices.pendingApproval({
        clientId,
        beginDate,
        endDate,
      });

      dispatch(clientActions.setClientApprovalItems(approvalResult.data));

      const invoicesResult = await InvoiceServices.previewInvoice({
        clientId,
        beginDate,
        endDate,
      });
      dispatch(clientActions.setClientPreviewInvoice(invoicesResult.data));
    });
  };
};

export const verifyInvoice = (
  clientId: number,
  beginDate: string,
  endDate: string
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      var timesheetCheckResults = await InvoiceServices.verifyInvoice({
        clientId,
        beginDate,
        endDate,
      });

      dispatch(
        clientActions.setTimesheetCheckItems(timesheetCheckResults.data)
      );
    });
  };
};

export const createContract = (model: CreateContractDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ContractServices.createContract(model);
      dispatch(clientActions.loadContracts(model.clientId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract created successfully',
        })
      );
    });
  };
};

export const terminateContract = (
  contractId: number,
  request: TerminateDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    const {
      client: { clientId },
    } = getState();
    await executeAction(dispatch, async () => {
      await ContractServices.terminateContract(contractId, request);
      dispatch(clientActions.loadContracts(clientId));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Contract terminated successfully',
        })
      );
    });
  };
};
