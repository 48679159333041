import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

export { usePrompt } from './usePrompt';

export { useUI } from './useUI';
export { useCurrentUser } from './useCurrentUser';
export { useLocalStorage } from './useLocalStorage';
export { useUser } from './useUser';
export { useOrganization } from './useOrganization';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
