import { ApiServices } from './ApiServices';
import {
  ClientDTO,
  SaveClientDTO,
  SaveClientPicDTO,
} from './models/ClientModel';
import { ContractDTO } from './models/ContractModel';

export class ClientServices {
  static getClients() {
    return ApiServices.get<Array<ClientDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/clients`
    );
  }

  static getClientById(clientId: number) {
    return ApiServices.get<ClientDTO>(
      `${process.env.REACT_APP_ACC_API}/api/clients/${clientId}`
    );
  }

  static getContractsByClientId(clientId: number) {
    return ApiServices.get<Array<ContractDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/clients/${clientId}/contracts`
    );
  }

  static createClientAsync(request: SaveClientDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/clientsupdate`,
      { request }
    );
  }

  static updateClientAsync(clientId: number, request: SaveClientDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/clientsupdate/${clientId}`,
      {
        request,
      }
    );
  }

  static activateClientAsync(clientId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/clientsupdate/${clientId}/activate`
    );
  }

  static deActivateClientAsync(clientId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/clientsupdate/${clientId}/deactivate`
    );
  }

  static saveProfilePicAsync(request: SaveClientPicDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/clientpicture/${request.clientId}/mainpic`,
      request
    );
  }
}
