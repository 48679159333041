import { PaginationFilterDTO } from './PaginationModel';

export enum BillStatusEnum {
  Submitted = 14002,
  Approved = 14003,
  Paid = 14004,
  Rejected = 14005,
}

export interface GetBillsByFilterDTO extends PaginationFilterDTO {
  startDate?: string;
  endDate?: string;
  statusId?: number;
  organizationId?: number;
}

export interface BillDTO {
  billId: number;
  userId: number;
  docId: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  startDate: string;
  endDate: string;
  billType: string;
  billStatusId: number;
  transactionReference?: string;
  currency: string;
  paidAmount?: number;
  feeAmount?: number;
  approvedUserId?: number;
  approvedDate?: string;
  submittedDate?: string;
}

export interface CreateBillDTO {
  startDate: string;
  endDate: string;
}

export interface ApproveBillDTO {
  currency: string;
  paidAmount: number;
  feeAmount: number;
}

export interface PayBillDTO {
  currency: string;
  paidAmount: number;
  feeAmount: number;
  transactionReference: string;
}

export interface GetBillsSummaryByFilterDTO {
  startDate?: string;
  endDate?: string;
}

export interface BillSummaryDTO {
  currency: string;
  totalPaidAmount: number;
  totalFeeAmount: number;
  totalBills: number;
}
