import { createSlice } from '@reduxjs/toolkit';
import { startOfWeek, endOfWeek, isSameDay, isAfter } from 'date-fns';
import { ContextActions } from '..';
import { toDate, dateToString, addDays } from '../../shared/DateUtil';
import { ClientWithTasksDTO } from '../../shared/services/models/ClientModel';
import { TimesheetEntryDTO } from '../../shared/services/models/TimesheetModel';
import { forceRefreshMethods, forceRefreshState } from '../pagination';

import {
  initializeTimesheets,
  loadTimesheets,
  saveTimesheet,
  submitTimesheets,
} from './timesheets-actions';

export interface WeekItem {
  items: Array<TimesheetEntryDTO>;
  date: string;
  bgColor?: string;
  color?: string;
}

export const timesheetsSlice = createSlice({
  name: 'timesheets',
  initialState: {
    myClients: [] as Array<ClientWithTasksDTO>,
    items: [] as Array<TimesheetEntryDTO>,
    editItem: null as TimesheetEntryDTO | null,
    editOpen: false,
    date: dateToString(new Date()) as string,
    selectedDate: '' as string,
    weekItems: [] as Array<WeekItem>,
    searchText: '' as string,

    lastLoadFilter: null,
    lastInitTimesheetFilter: null,
    canSubmitForApproval: false,
    submittedDate: '',
    approvedDate: '',

    ...forceRefreshState(),
  },
  reducers: {
    ...forceRefreshMethods(),
    reset(state) {
      Object.assign(state, timesheetsSlice.getInitialState());
    },
    setItems(state, action) {
      const items: Array<TimesheetEntryDTO> = action.payload;
      state.canSubmitForApproval =
        items.filter((x) => !x.approvedDate).length > 0;
      state.submittedDate =
        items.find((x) => x.submittedDate)?.submittedDate ?? '';
      state.approvedDate =
        items.find((x) => x.approvedDate)?.approvedDate ?? '';

      items.sort((a, b) =>
        isAfter(toDate(a.taskDate!)!, toDate(b.taskDate!)!) ? 1 : -1
      );
      state.items = items;
      state.weekItems.forEach((wi) => {
        wi.items = state.items.filter((x) =>
          isSameDay(toDate(x.taskDate)!, toDate(wi.date)!)
        );
      });
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    goSelectedBack(state) {
      state.selectedDate =
        dateToString(addDays(-1, toDate(state.selectedDate ?? state.date)!)) ??
        '';
    },
    goSelectedNext(state) {
      state.selectedDate =
        dateToString(addDays(1, toDate(state.selectedDate ?? state.date)!)) ??
        '';
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setDate(state, action) {
      state.date = action.payload;
      const arr = [];
      if (state.date) {
        let start = startOfWeek(toDate(`${state.date}`)!);
        const end = endOfWeek(toDate(`${state.date}`)!);

        while (start < end) {
          const date = start;
          arr.push({
            date: dateToString(date)!,
            items: [],
          });

          start = addDays(1, start);
        }
      }
      state.weekItems = arr;
      state.weekItems.forEach((wi) => {
        wi.items = state.items.filter((x) =>
          isSameDay(toDate(x.taskDate)!, toDate(wi.date)!)
        );
      });
    },
    setClients(state, action) {
      state.myClients = action.payload;
    },
    setEditItem(state, action) {
      state.editItem = action.payload;
      state.selectedDate = state.editItem?.taskDate ?? '';
      state.editOpen = !!state.editItem;
    },
    setLastLoadFilter(state, action) {
      state.lastLoadFilter = action.payload;
    },
    setLastInitTimesheetFilter(state, action) {
      state.lastInitTimesheetFilter = action.payload;
    },
  },
});

export const timesheetsActions = {
  ...timesheetsSlice.actions,
  initializeTimesheets,
  loadTimesheets,
  saveTimesheet,
  submitTimesheets,
};

const extra = {
  onBack: (v?: any) => {},
  onNext: (v?: any) => {},
};

export type TimesheetsContextType = ReturnType<
  typeof timesheetsSlice.getInitialState
> &
  Partial<ContextActions<typeof timesheetsActions>> &
  typeof extra;

export const TimesheetsContextValue = {
  ...timesheetsSlice.getInitialState(),
  ...extra,
};
