import { createSlice } from '@reduxjs/toolkit';
import { loadOrgs, saveOrganization } from './orgs-actions';
import { ContextActions } from '..';
import { UserOrganizationDTO } from '../../shared/services/models/UserOrganizationModel';
import { PublicHolidayDTO } from '../../shared/services/models/TimeoffModel';

export const orgsSlice = createSlice({
  name: 'orgs',
  initialState: {
    organizations: [] as Array<UserOrganizationDTO>,
    selectedOrganization: null as UserOrganizationDTO | null,
    holidays: [] as Array<PublicHolidayDTO>,
    open: false,
    locked: false,
  },
  reducers: {
    setOrganizations(state, action) {
      state.organizations = action.payload;
      state.selectedOrganization = null;
      state.open = true;
      if (state.organizations.length === 1) {
        state.selectedOrganization = { ...state.organizations[0] };
        state.open = false;
        localStorage.setItem(
          'OrganizationId',
          `${state.selectedOrganization?.organizationId}`
        );
      }
    },
    selectOrganization(state, action) {
      state.selectedOrganization = { ...action.payload };
      state.open = false;
      localStorage.setItem(
        'OrganizationId',
        `${state.selectedOrganization?.organizationId}`
      );
    },
    showOrganizations(state) {
      state.open = true;
    },
    hideOrganizations(state) {
      state.open = false;
    },
    lock(state) {
      state.locked = true;
    },
    unlock(state) {
      state.locked = false;
    },
    setPublicHolidays(state, action) {
      state.holidays = action.payload;
    },
  },
});

export const orgsActions = {
  ...orgsSlice.actions,
  loadOrgs,
  saveOrganization,
};

export type OrgsContextType = ReturnType<typeof orgsSlice.getInitialState> &
  Partial<ContextActions<typeof orgsActions>>;

export const OrgsContextValue = orgsSlice.getInitialState();
