import { Box } from '@mui/material';

export function AnimatedClientNotFound() {
  return (
    <Box
      display={'flex'}
      rowGap={2}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <div>Not Found</div>
    </Box>
  );
}
