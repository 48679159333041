import { PaginationFilterDTO } from './PaginationModel';

export enum InvoiceStatusEnum {
  UnPaid = 13002,
  Paid = 13003,
  Overdue = 13004,
}

export interface InvoiceInfoDTO {
  invoiceId: number;
  invoiceDate: string;

  beginDate: string;
  endDate: string;
  dueDate: string;

  clientId: number;
  clientName: string;

  userId: number;
  firstName: string;
  lastName: string;

  billingRate: number;

  invoicePeriod: string;

  statusId: number;
}

export interface InvoicePickItemDTO {
  invoiceId: number;
  invoiceDate: string;

  beginDate: string;
  endDate: string;
  dueDate: string;

  clientId: number;
  clientName: string;

  totalAmount: number;

  invoicePeriod: string;

  statusId: number;
}

export interface InvoiceItemInfo {
  userTaskId: number;
  taskId: number;
  userTask: string;
  date: string;
  hours: number;
  billingRate: number;
  paidAmount: number;

  userFullName: string;
}
export interface AddressDTO {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
}
export interface InvoiceInfo {
  items: InvoiceItemInfo[];
  invoiceTotal: number;
  invoiceId: number;
  invoiceDate: Date;
  beginDate: Date;
  endDate: Date;
  paidDate?: any;
  dueDate?: any;
  clientId: number;
  clientName: string;
  logoUrl?: any;

  organizationName: string;
  organizationIconUrl: string;

  invoicePeriod: string;
  statusId: number;
  taskId?: any;
  task?: any;
  hours?: any;

  remitToAddress?: AddressDTO;
}

export interface CreateInvoiceDTO {
  clientId: number;
  beginDate: string;
  endDate: string;
}

export interface NeedsApprovalItemDTO {
  date: string;
  userFullName: string;
  hours: number;
}

export interface InvoicePreviewItemDTO {
  date: string;
  userFullName: string;
  userTask: string;
  hours: number;
  billingRate: number;
}

export interface GetInvoicesByFilterDTO extends PaginationFilterDTO {
  clientId?: number;
  startDate?: string;
  endDate?: string;
  statusId?: number;

  organizationId?: number;
}

export interface InvoiceSummaryDTO {
  totalCount?: number;
  totalAmount?: number;

  paidCount?: number;
  paidAmount?: number;

  unPaidCount?: number;
  unPaidAmount?: number;

  overdueCount?: number;
  overdueAmount?: number;
}

export interface TimesheetCheckDTO {
  weekendDate: string;
  userFullName: string;
  hours: number;
  externalHours: number;
}

export interface CompleteInvoiceDTO {
  invoiceId: number;
  paidDate: string;
  paymentMethodId: number;
  transactionRef: string;
  amount: number;
  feeAmount: number;
  currency: string;
}
