import { ApiServices } from './ApiServices';
import {
  TimeoffDTO,
  GetTimeoffsByFilterDTO,
  SaveTimeoffDTO,
  UserTimeoffSummaryDTO,
  PublicHolidayDTO,
} from './models/TimeoffModel';
import { PaginationResultDTO } from './models/PaginationModel';

export class UserTimeoffServices {
  static getTimeoffsByFilterAsync(request: GetTimeoffsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<TimeoffDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/search`,
      {
        request,
      }
    );
  }

  static getTimeoffSummaryAsync() {
    return ApiServices.post<Array<UserTimeoffSummaryDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/summary`
    );
  }

  static getPublicHolidaysAsync() {
    return ApiServices.get<Array<PublicHolidayDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/holidays`
    );
  }

  static createAsync(model: SaveTimeoffDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/create`,
      model
    );
  }

  static updateAsync(timeoffId: number, model: SaveTimeoffDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/${timeoffId}/update`,
      model
    );
  }

  static approveAsync(timeoffId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/${timeoffId}/approve`
    );
  }

  static rejectAsync(timeoffId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usertimeoff/${timeoffId}/reject`
    );
  }
}
