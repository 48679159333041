import {
  InitTimesheetDTO,
  LoadTimesheetDTO,
  SaveUserTaskDTO,
} from '../../shared/services/models/TimesheetModel';
import { TimesheetServices } from '../../shared/services/TimesheetServices';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { dateToString, toDate } from '../../shared/DateUtil';
import { uiActions, timesheetsActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';

import { shallowEqual } from 'react-redux';

export const loadTimesheets = (filter: LoadTimesheetDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        timesheets: { lastLoadFilter },
      } = getState();

      if (!lastLoadFilter || !shallowEqual(filter, lastLoadFilter)) {
        dispatch(timesheetsActions.setLastLoadFilter(filter));
        const { date, searchText } = filter;

        const start = format(startOfWeek(toDate(date)!), 'yyyy-MM-dd');
        const end = format(endOfWeek(toDate(date)!), 'yyyy-MM-dd');
        const result = await TimesheetServices.getTimesheetForDateAsync(
          start,
          end,
          searchText
        );
        dispatch(timesheetsActions.setItems(result.data || []));
      }
    });
  };
};

export const initializeTimesheets = (filter: InitTimesheetDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        timesheets: { lastInitTimesheetFilter },
      } = getState();

      if (
        !lastInitTimesheetFilter ||
        !shallowEqual(filter, lastInitTimesheetFilter)
      ) {
        dispatch(timesheetsActions.setLastInitTimesheetFilter(filter));
        const { date } = filter;

        const contractResult = await TimesheetServices.getMyContractsAsync();
        const taskResult = await TimesheetServices.getMyTasksAsync();
        const clients = contractResult.data.map((x) => ({
          ...x,
          ...x.client,
          tasks: taskResult.data.filter((y) => y.contractId === x.contractId),
        }));
        dispatch(timesheetsActions.setDate(date));
        dispatch(timesheetsActions.setClients(clients));
      }
    });
  };
};

export const saveTimesheet = (model: SaveUserTaskDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        timesheets: { items, myClients },
      } = getState();

      const result = await TimesheetServices.saveTimesheet(model);

      const client = myClients.find((x) => x.contractId === model.contractId);
      const sModel = {
        ...model,
        userTaskId: result.data,
        clientName: client?.clientName || '',
        taskName:
          client?.tasks.find((x) => x.taskId === model.taskId)?.taskName || '',
      };

      const newList = items.filter((x) => x.userTaskId !== sModel.userTaskId);
      if (model.hours > 0) {
        newList.push({
          ...sModel,
          contractName: '',
          contractId: model.contractId,
          invoiceId: 0,
        });
      }
      dispatch(timesheetsActions.setItems(newList));

      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'Timesheet entry was successful',
        })
      );
    });
  };
};

export const submitTimesheets = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        timesheets: { date, lastLoadFilter },
      } = getState();

      await TimesheetServices.submitTimesheetsAsync({
        beginDate: dateToString(startOfWeek(toDate(`${date}`)!)) ?? '',
        endDate: dateToString(endOfWeek(toDate(`${date}`)!)) ?? '',
      });

      dispatch(
        timesheetsActions.loadTimesheets({
          ...(lastLoadFilter ?? {}),
          x: new Date(),
        } as any)
      );

      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'Timesheet successfully submitted',
        })
      );
    });
  };
};
