import { ApiServices } from './ApiServices';
import { DashboardFilterDTO } from './models/DashboardModel';
import { PaginationResultDTO } from './models/PaginationModel';
import {
  GetUsersByFilterDTO,
  UserInfoDTO,
  UserDTO,
  SaveUserDTO,
  TerminateUserDTO,
  RoleDTO,
  ProfileDocumentDTO,
} from './models/UserModel';
import { UserTaskItemDTO } from './models/UserTaskModel';

export class UserServices {
  static getUsersByFilterAsync(request: GetUsersByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<UserInfoDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/users/search`,
      {
        request,
      }
    );
  }

  static createUserAsync(request: SaveUserDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/users`,
      {
        request,
      }
    );
  }

  static updateUserAsync(userId: number, request: SaveUserDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/users/${userId}`,
      {
        request,
      }
    );
  }

  static terminateUserAsync(userId: number, request: TerminateUserDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/users/${userId}/terminate`,
      {
        request,
      }
    );
  }

  static assignUserManagerAsync(userId: number, managerUserId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/users/${userId}/assign/${managerUserId}`
    );
  }

  static getRolesAsync() {
    return ApiServices.get<Array<RoleDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/users/roles`
    );
  }

  static getUsersByIdAsync(userId: number) {
    return ApiServices.get<UserDTO>(
      `${process.env.REACT_APP_ACC_API}/api/users/${userId}/details`
    );
  }

  static getDocumentAsync(userId: number) {
    return ApiServices.get<Array<ProfileDocumentDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/userdocument/${userId}`
    );
  }

  static getDocumentUrlAsync(userId: number, docId: string) {
    return ApiServices.get<string>(
      `${process.env.REACT_APP_ACC_API}/api/userdocument/${userId}/${docId}`
    );
  }

  static validateUserNameAsync(userId: number, userName: string) {
    return ApiServices.post<{
      isAvailable: boolean;
    }>(`${process.env.REACT_APP_ACC_API}/api/users/${userId}/username`, {
      userName,
    });
  }

  static approveDocAsync(userId: number, docId: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/userdocument/${userId}/${docId}/approve`
    );
  }

  static rejectDocAsync(userId: number, docId: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/userdocument/${userId}/${docId}/reject`
    );
  }

  static getUserTimesheetsAsync(userId: number, request: DashboardFilterDTO) {
    return ApiServices.post<Array<UserTaskItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/usertimesheets/${userId}`,
      { request }
    );
  }
}
