import { timeoffsActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { shallowEqual } from 'react-redux';

import { UserTimeoffServices } from '../../shared/services/UserTimeoffServices';
import {
  GetTimeoffsByFilterDTO,
  SaveTimeoffDTO,
} from '../../shared/services/models/TimeoffModel';

export const initialize = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await UserTimeoffServices.getTimeoffSummaryAsync();
      dispatch(timeoffsActions.setSummary(result.data || []));

      const result2 = await UserTimeoffServices.getPublicHolidaysAsync();
      dispatch(timeoffsActions.setPublicHolidays(result2.data || []));
    });
  };
};

export const search = (filter: GetTimeoffsByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        timeoffs: { items, lastFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !lastFilter ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(timeoffsActions.setItems([]));
        dispatch(timeoffsActions.setLastFilter(filter));
        const result = await UserTimeoffServices.getTimeoffsByFilterAsync(
          filter
        );
        dispatch(timeoffsActions.setItems(result.data.items || []));
        dispatch(timeoffsActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const saveTimeoff = (model: SaveTimeoffDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (model.timeoffId > 0) {
        await UserTimeoffServices.updateAsync(model.timeoffId, model);
      } else {
        await UserTimeoffServices.createAsync(model);
      }
      dispatch(timeoffsActions.setSelected(null));
      dispatch(timeoffsActions.doForceRefresh());
    });
  };
};

export const approve = (timeoffId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserTimeoffServices.approveAsync(timeoffId);
      dispatch(timeoffsActions.doForceRefresh());
    });
  };
};

export const reject = (timeoffId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserTimeoffServices.rejectAsync(timeoffId);
      dispatch(timeoffsActions.doForceRefresh());
    });
  };
};
