import axios, { AxiosResponse } from 'axios';

export const authToken = 'auth-token';
axios.defaults.withCredentials = true;

export class ApiServices {
  static initialize() {}
  static getHeaders() {}

  static getAccessToken() {
    const strToken = localStorage.getItem(authToken) || '{}';
    const token = JSON.parse(strToken);
    return token?.accessToken || '';
  }

  static getRefreshToken() {
    const strToken = localStorage.getItem(authToken) || '{}';
    const token = JSON.parse(strToken);
    return token?.refreshToken || '';
  }

  static signOut() {
    localStorage.clear();
    window.location.href = '/login';
  }

  static refreshPromise: Promise<any> | null = null;

  static refreshToken() {
    if (!ApiServices.refreshPromise) {
      ApiServices.refreshPromise = new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_ACC_API}/api/auth/refresh`, {
            refreshToken: ApiServices.getRefreshToken(),
          })
          .then((result) => {
            localStorage.setItem(authToken, JSON.stringify(result.data));
            resolve(result.data);
            ApiServices.refreshPromise = null;
          })
          .catch((error) => {
            //if (error?.response?.status === 401) {
            ApiServices.signOut();
            //}
          });
      });
    }
    return ApiServices.refreshPromise;
  }

  private static async sendAsync<T>(
    apiCall: () => Promise<AxiosResponse<T, any>>
  ) {
    return new Promise<AxiosResponse<T, any>>((resolve, reject) => {
      apiCall()
        .then((result1) => resolve(result1))
        .catch((error1) => {
          if (error1?.response?.status === 401) {
            ApiServices.refreshToken()
              .then(() => {
                apiCall()
                  .then((result2) => resolve(result2))
                  .catch((error2) => reject(error2));
              })
              .catch((error3) => {
                reject(error3);
              });
          } else {
            reject(error1);
          }
        });
    });
  }

  static async get<T>(url: string) {
    const apiCall = () => {
      return axios.get<T>(url, {
        headers: {
          Authorization: `Bearer ${ApiServices.getAccessToken()}`,
          OrganizationId: localStorage.getItem('OrganizationId') ?? '0',
          UserId: localStorage.getItem('UserId') ?? '0',
        },
      });
    };
    return ApiServices.sendAsync<T>(apiCall);
  }

  static async post<T>(url: string, data?: any) {
    const apiCall = () => {
      return axios.post<T>(url, data, {
        headers: {
          Authorization: `Bearer ${ApiServices.getAccessToken()}`,
          OrganizationId: localStorage.getItem('OrganizationId') ?? '0',
          UserId: localStorage.getItem('UserId') ?? '0',
        },
      });
    };
    return ApiServices.sendAsync<T>(apiCall);
  }

  static async postForm<T>(url: string, data?: any) {
    const apiCall = () => {
      return axios.post<T>(url, data, {
        headers: {
          Authorization: `Bearer ${ApiServices.getAccessToken()}`,
          OrganizationId: localStorage.getItem('OrganizationId') ?? '0',
          UserId: localStorage.getItem('UserId') ?? '0',
          'Content-Type': 'multipart/form-data',
        },
      });
    };
    return ApiServices.sendAsync<T>(apiCall);
  }

  static getUrlString(data: any) {
    const param = (a: any) => {
      const s: Array<any> = [];
      const add = (k: any, v: any) => {
        v = typeof v === 'function' ? v() : v;
        v = v === null ? '' : v === undefined ? '' : v;
        s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
      };
      const buildParams = (prefix: any, obj: any) => {
        let i;
        let len;

        if (prefix) {
          if (Array.isArray(obj)) {
            for (i = 0, len = obj.length; i < len; i++) {
              buildParams(
                prefix +
                  '[' +
                  (typeof obj[i] === 'object' && obj[i] ? i : '') +
                  ']',
                obj[i]
              );
            }
          } else if (String(obj) === '[object Object]') {
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                buildParams(prefix + '[' + key + ']', obj[key]);
              }
            }
          } else {
            add(prefix, obj);
          }
        } else if (Array.isArray(obj)) {
          for (i = 0, len = obj.length; i < len; i++) {
            add(obj[i].name, obj[i].value);
          }
        } else {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              buildParams(key, obj[key]);
            }
          }
        }
        return s;
      };

      return buildParams('', a).join('&');
    };

    return param(data);
  }

  static async getFilterData<T>(url: string, filter?: any) {
    const cUrl = `${url}?filter=${filter ? JSON.stringify(filter) : ''}`;
    return await ApiServices.get<T>(cUrl);
  }
}

ApiServices.initialize();
