import { createContext, useCallback, useContext, useEffect } from 'react';

import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector, useUser } from '../hooks';
import {
  authActions,
  clientsActions,
  dashboardActions,
  invoicesActions,
  timesheetsActions,
} from '../store';

import {
  orgsActions,
  OrgsContextType,
  OrgsContextValue,
} from '../store/orgs/orgs-slice';

export const OrgsContext = createContext<OrgsContextType>(OrgsContextValue);

export function useOrgs() {
  const context = useContext(OrgsContext);
  if (!context) {
    throw new Error('OrgsContext must be used within OrgsContextProvider!');
  }
  return context;
}

export function OrgsContextProvider(props: any) {
  const dispatch = useAppDispatch();
  const user = useUser();

  const { organizations, selectedOrganization, holidays, open, locked } =
    useAppSelector(
      ({
        orgs: { organizations, selectedOrganization, holidays, open, locked },
      }) => ({
        organizations,
        selectedOrganization,
        holidays,
        open,
        locked,
      }),
      shallowEqual
    );

  const selectOrganization = useCallback(
    (org: any) => {
      dispatch(authActions.switchOrganization(org));
    },
    [dispatch]
  );

  const showOrganizations = useCallback(() => {
    dispatch(orgsActions.showOrganizations());
  }, [dispatch]);

  const hideOrganizations = useCallback(() => {
    dispatch(orgsActions.hideOrganizations());
  }, [dispatch]);

  const saveOrganization = useCallback(
    (org: any) => {
      dispatch(orgsActions.saveOrganization(org));
    },
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      dispatch(orgsActions.loadOrgs());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && selectedOrganization) {
      dispatch(clientsActions.reset());
      dispatch(dashboardActions.reset());
      dispatch(timesheetsActions.reset());
      dispatch(invoicesActions.reset());
    }
  }, [dispatch, user, selectedOrganization]);

  const providerValue: OrgsContextType = {
    organizations,
    selectedOrganization,
    holidays,
    open,
    locked,
    selectOrganization,
    showOrganizations,
    hideOrganizations,
    saveOrganization,
  };

  return (
    <OrgsContext.Provider value={providerValue}>
      {props.children}
    </OrgsContext.Provider>
  );
}
