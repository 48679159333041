import { useMemo } from 'react';
import { useUser } from './useUser';

interface CurrentUser {
  canViewRevenue?: boolean;
  canViewResources?: boolean;
  canViewTimeSpent?: boolean;
  canViewClient?: boolean;
  canEditClient?: boolean;
  canAddClient?: boolean;
  canToggleClientStatus?: boolean;
  canAddContract?: boolean;
  canAddUserToContract?: boolean;
  canAddTaskToContract?: boolean;
  canAddUser?: boolean;
  canViewUser?: boolean;
  canEditUser?: boolean;
  canGenerateInvoice?: boolean;
  canViewInvoice?: boolean;
  canApproveTimesheets?: boolean;
  canApproveInvoice?: boolean;
  canTerminateContract?: boolean;
  canRemoveUserFromContract?: boolean;
  canViewOrganization?: boolean;
  canAssignManager?: boolean;
  canViewUserDocuments?: boolean;
  canApproveBills?: boolean;
  canApproveTimeoffs?: boolean;

  SUPER?: boolean;
  ORGADM?: boolean;
  USER?: boolean;
  PRJADM?: boolean;
  BILLADM?: boolean;
}

const camleCase = (str: string) => str[0].toLowerCase() + str.substring(1);

export function useCurrentUser() {
  const user = useUser();

  const currentUser = useMemo<CurrentUser>(() => {
    const cUser: CurrentUser = {};
    if (user && user?.perm) {
      const permissions = Array.isArray(user?.perm) ? user?.perm : [user?.perm];
      permissions.forEach((p) => {
        (cUser as any)[camleCase(p)] = true;
      });
    }

    if (user && user?.role) {
      const roles = Array.isArray(user?.role) ? user?.role : [user?.role];
      roles.forEach((p) => {
        (cUser as any)[p] = true;
      });
    }

    return cUser;
  }, [user]);

  return currentUser;
}
