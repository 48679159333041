import { ApiServices } from './ApiServices';
import {
  VendorBillDTO,
  GetVendorBillsByFilterDTO,
  GetVendorsByFilterDTO,
  VendorDTO,
} from './models/VendorBillsModel';
import { PaginationResultDTO } from './models/PaginationModel';
import { ApproveBillDTO, PayBillDTO } from './models/BillsModel';

export class VendorBillServices {
  static getVendorsByFilterAsync(request: GetVendorsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<VendorDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/vendors/search`,
      {
        request,
      }
    );
  }

  static getVendorBillsByFilterAsync(request: GetVendorBillsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<VendorBillDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/search`,
      {
        request,
      }
    );
  }

  static createBillAsync(vendorId: number, model: FormData) {
    return ApiServices.postForm<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/create`,
      model
    );
  }

  static approveBillAsync(
    vendorId: number,
    billId: number,
    model: ApproveBillDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${billId}/approve`,
      model
    );
  }

  static payBillAsync(vendorId: number, billId: number, model: PayBillDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${billId}/pay`,
      model
    );
  }

  static rejectBillAsync(vendorId: number, billId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${billId}/reject`
    );
  }

  static getDocumentUrlAsync(vendorId: number, docId: string) {
    return ApiServices.get<string>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${docId}`
    );
  }
}
