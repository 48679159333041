import { RootState, uiActions } from '.';

export type DISPATCH = (data: any) => void;
export type GET_STATE = () => RootState;

export async function executeAction(
  dispatch: (d: any) => void,
  action: () => Promise<any>
) {
  dispatch(uiActions.setLoading(true));
  try {
    return await action();
  } catch (error: any) {
    let msg = '';
    const data = error.response.data;
    if (data) {
      if (data.Message) {
        msg = data.Message;
      } else {
        Object.keys(data).forEach((k) => {
          data[k]?.forEach((v: any) => {
            msg += v;
          });
        });
      }
    }

    dispatch(
      uiActions.setMessage({
        messageType: 'error',
        message: msg || 'Unknown error',
      })
    );
  } finally {
    dispatch(uiActions.setLoading(false));
  }
}
