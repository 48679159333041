import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { AdminServices } from '../../shared/services/AdminServices';
import { rolesAndPermissionsActions } from './roles-permissions-slice';

export const search = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        rolesAndPermissions: { items },
      } = getState();
      if (!items || items.length === 0) {
        const result = await AdminServices.getRolesAsync();
        dispatch(rolesAndPermissionsActions.setItems(result.data));
      }
    });
  };
};
