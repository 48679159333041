import { createSlice } from '@reduxjs/toolkit';
import { loadBalanceSheet } from './accounting-actions';
import { ContextActions } from '..';
import { BalanceSheetDTO } from '../../shared/services/models/AccountingModel';

export const accountingSlice = createSlice({
  name: 'accounting',
  initialState: {
    balanceSheet: [] as BalanceSheetDTO[],
  },
  reducers: {
    setBalanceSheet(state, action) {
      state.balanceSheet = action.payload;
    },
  },
});

export const accountingActions = {
  ...accountingSlice.actions,
  loadBalanceSheet,
};

export type AccountingContextType = ReturnType<
  typeof accountingSlice.getInitialState
> &
  Partial<ContextActions<typeof accountingActions>>;

export const AccountingContextValue = accountingSlice.getInitialState();
