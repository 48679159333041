import './App.css';
import { Box, CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router';
import { RequireNoAuth, RequireAuth, Layout } from './components/Layout';
import { customTheme } from './theme/customTheme';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { lazy, useEffect } from 'react';
import { LoginPage } from './pages/login/LoginPage';
import { authActions, uiActions } from './store';

import { useAppDispatch, useAppSelector, useCurrentUser } from './hooks';
import { PageNotFound } from './pages/common/PageNotFound';

import { MessageBoxManagerProvider } from 'mui-message-box';
import { OrgsContextProvider } from './contexts/OrgContext';
import { AnimatePresence } from 'framer-motion';
import { NotificationHubContextProvider } from './contexts/NotificationHubContext';

const ClientsDashboardPage = lazy(
  () => import('./pages/clients/dashboard/ClientsDashboardPage')
);
const ClientDetailsPage = lazy(
  () => import('./pages/clients/details/ClientDetailsPage')
);
const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));
const InvoiceDashboardPage = lazy(
  () => import('./pages/invoices/dashboard/InvoiceDashboardPage')
);
const InvoiceDetailsPage = lazy(
  () => import('./pages/invoices/details/InvoiceDetailsPage')
);
const TimesheetsPage = lazy(() => import('./pages/timesheets/TimesheetsPage'));

const ContractDetailsPage = lazy(
  () => import('./pages/contract/ContractDetailsPage')
);
const UsersDashboardPage = lazy(
  () => import('./pages/users/dashboard/UsersDashboardPage')
);
const UserEditPage = lazy(() => import('./pages/users/edit/UserEditPage'));
const OrganizationsPage = lazy(
  () => import('./pages/organizations/OrganizationsPage')
);
const RolesAndPermissionsPage = lazy(
  () => import('./pages/roles-permissions/RolesAndPermissionsPage')
);

const MyProfilePage = lazy(
  () => import('./pages/profile/dashboard/MyProfilePage')
);
const ChangePasswordPage = lazy(
  () => import('./pages/profile/change-password/ChangePasswordPage')
);
const ForgotPasswordPage = lazy(
  () => import('./pages/profile/forgot-password/ForgotPasswordPage')
);
const PasswordResetPage = lazy(
  () => import('./pages/profile/password-reset/PasswordResetPage')
);
const ApprovalsDashboardPage = lazy(
  () => import('./pages/approvals/ApprovalsDashboardPage')
);

const BillDashboardPage = lazy(() => import('./pages/bills/BillDashboardPage'));

const TimeoffDashboardPage = lazy(
  () => import('./pages/timeoffs/dashboard/TimeoffDashboardPage')
);

const MyOrganizationPage = lazy(
  () => import('./pages/my-organization/MyOrganizationPage')
);

const AccountingPage = lazy(() => import('./pages/accounting/AccountingPage'));

function GlobalProgressBar() {
  const loading = useAppSelector((state) => state.ui.loading);
  return (
    <Box
      sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 9999 }}
    >
      <>{loading && <LinearProgress />}</>
    </Box>
  );
}

function GlobalMessageBar() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.ui.message);
  const messageType = useAppSelector((state) => state.ui.messageType);

  useEffect(() => {
    if (message && messageType) {
      enqueueSnackbar(message, {
        variant: messageType,
        autoHideDuration: 5000,
      });
      dispatch(uiActions.clearMessage());
    }
  }, [message, messageType, enqueueSnackbar, dispatch]);

  return <></>;
}

function AppRoutes() {
  const currentUser = useCurrentUser();
  const location = useLocation();
  /*exitBeforeEnter*/
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          element={
            <RequireNoAuth>
              <LoginPage />
            </RequireNoAuth>
          }
        />

        <Route
          path={`${process.env.PUBLIC_URL}/forgot`}
          element={
            <RequireNoAuth>
              <ForgotPasswordPage />
            </RequireNoAuth>
          }
        />

        <Route
          path={`${process.env.PUBLIC_URL}/passwordreset/:recoverToken`}
          element={
            <RequireNoAuth>
              <PasswordResetPage />
            </RequireNoAuth>
          }
        />

        <Route
          path={`${process.env.PUBLIC_URL}/`}
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path={`/`} element={<DashboardPage />} />
          <Route path={`/timesheets`} element={<TimesheetsPage />} />
          <Route path={`/profile`} element={<MyProfilePage />} />
          <Route path={`/changepassword`} element={<ChangePasswordPage />} />

          <Route path={`/bills`} element={<BillDashboardPage />} />
          <Route path={`/timeoffs`} element={<TimeoffDashboardPage />} />

          {currentUser.canViewOrganization && (
            <Route path={`/organizations`} element={<OrganizationsPage />} />
          )}
          {currentUser.ORGADM && (
            <Route path={`/myorganization`} element={<MyOrganizationPage />} />
          )}

          {currentUser.canViewOrganization && (
            <Route
              path={`/rolesandperm`}
              element={<RolesAndPermissionsPage />}
            />
          )}

          {currentUser.canViewClient && (
            <Route path={`/clients`} element={<ClientsDashboardPage />} />
          )}
          {currentUser.canViewClient && (
            <Route
              path={`/clients/:clientId`}
              element={<ClientDetailsPage />}
            />
          )}
          {currentUser.canViewClient && (
            <Route
              path={`/clients/:clientId/contract/:contractId`}
              element={<ContractDetailsPage />}
            />
          )}
          {currentUser.canViewUser && (
            <Route path={`/users`} element={<UsersDashboardPage />} />
          )}
          {currentUser.canEditUser && (
            <Route path={`/users/:userId`} element={<UserEditPage />} />
          )}

          {currentUser.canViewInvoice && (
            <Route path={`/invoices`} element={<InvoiceDashboardPage />} />
          )}
          {currentUser.canViewInvoice && (
            <Route path={`/accounting`} element={<AccountingPage />} />
          )}
          {currentUser?.canViewInvoice && (
            <Route
              path={`/invoices/:invoiceId`}
              element={<InvoiceDetailsPage />}
            />
          )}
          {currentUser.canApproveTimesheets && (
            <Route path={`/approvals`} element={<ApprovalsDashboardPage />} />
          )}

          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(authActions.initialize());
  }, [dispatch]);
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <SnackbarProvider
        hideIconVariant={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <NotificationHubContextProvider
          hubUrl={`${process.env.REACT_APP_ACC_API}/api/notificationhub`}
        >
          <GlobalProgressBar />
          <GlobalMessageBar />
          <MessageBoxManagerProvider>
            <BrowserRouter>
              <OrgsContextProvider>
                <AppRoutes />
              </OrgsContextProvider>
            </BrowserRouter>
          </MessageBoxManagerProvider>
        </NotificationHubContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
