import { useState, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import { createContext, useContext } from 'react';
import { ApiServices } from '../shared/services/ApiServices';

export const NotificationHubContext = createContext<{
  isConnected: boolean;
  error?: any;
  serverData?: any;
  setServerData?: (a: any) => void;
}>({
  isConnected: false,
});

export function useNotificationHub() {
  const context = useContext(NotificationHubContext);
  if (!context) {
    throw new Error(
      'NotificationHubContext must be used within NotificationHubContextProvider!'
    );
  }
  return context;
}

export function NotificationHubContextProvider(props: any) {
  const { hubUrl } = props;
  const [connection, setConnection] = useState<any>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<any>(null);
  const [serverData, setServerData] = useState<any>(null);

  const connectionRef = useRef<any>();

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        headers: {
          Authorization: `Bearer ${ApiServices.getAccessToken()}`,
        },
      })
      .build();

    connectionRef.current = newConnection;

    setConnection(newConnection);

    return () => {
      connectionRef.current.stop();
    };
  }, [hubUrl, connectionRef]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setIsConnected(true);
          setError(null);
        })
        .catch((err: any) => {
          setIsConnected(false);
          setError(err);
        });

      connection.onclose(() => {
        setIsConnected(false);
      });

      connection.on('onServerUpdate', (message: any) => {
        setServerData(message);
      });
    }
  }, [connection, setServerData]);

  const providerValue = { isConnected, error, setServerData, serverData };

  return (
    <NotificationHubContext.Provider value={providerValue}>
      {props.children}
    </NotificationHubContext.Provider>
  );
}
