import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  styled,
  Container,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router';
import { MainMenu } from './MainMenu';
import { UserProfileMenu } from '../user-profile/UserProfileMenu';

import { useOrganization } from '../../hooks';
import { Suspense } from 'react';
import { motion } from 'framer-motion';
import { NotificationHub } from '../notification-hub/NotificationHub';

export function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      KIPROSH America Inc. {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const StyledMain = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr auto',
  '& .MuiToolbar-root': {
    gridGap: 4,
  },
  '& > main': {
    flex: 1,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  '& footer': {
    height: 60,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
  },
}));

function Loading() {
  return (
    <Box
      display={'flex'}
      rowGap={2}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <CircularProgress />
      <div>Loading...</div>
    </Box>
  );
}

function LayoutInternal() {
  const navigate = useNavigate();
  const { selectedOrganization } = useOrganization();
  return (
    <StyledMain>
      <AppBar position='sticky' color='inherit'>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box display='flex' alignItems='center' rowGap={1} columnGap={1}>
            <a onClick={() => navigate('/')} href={'/'}>
              <Avatar
                src={`${selectedOrganization?.iconUrl}`}
                variant='square'
              ></Avatar>
            </a>
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              onClick={() => navigate('/')}
              sx={{ cursor: 'pointer', display: ['none', 'block'] }}
            >
              Time Track
            </Typography>
          </Box>
          <Box flex={1}></Box>
          <MainMenu />
          <UserProfileMenu />
          <NotificationHub />
        </Toolbar>
      </AppBar>
      <Box component='main'>
        <Suspense fallback={<Loading />}>
          {selectedOrganization && <Outlet />}
        </Suspense>
      </Box>
      <Box component='footer'>
        <Copyright />
      </Box>
    </StyledMain>
  );
}

export function Layout() {
  return <LayoutInternal />;
}

export function Page({ maxWidth, children }: any) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <Container
        maxWidth={maxWidth || 'lg'}
        sx={{ p: 1, pt: 2, width: '100vw' }}
      >
        {children}
      </Container>
    </motion.div>
  );
}
