import { createSlice } from '@reduxjs/toolkit';
import { ClientDTO } from '../../shared/services/models/ClientModel';
import { ContractDTO } from '../../shared/services/models/ContractModel';

import {
  loadClient,
  loadContracts,
  createInvoice,
  previewInvoice,
  verifyInvoice,
  createContract,
  terminateContract,
  saveProfilePic,
} from './client-actions';
import {
  InvoicePreviewItemDTO,
  NeedsApprovalItemDTO,
  TimesheetCheckDTO,
} from '../../shared/services/models/InvoiceModel';
import { ContextActions } from '..';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    filter: 0,
    clientId: 0,
    contractId: 0,
    showCreateInvoice: false,
    showCreateContract: false,
    showProfilePicEdit: false,

    client: null as ClientDTO | null,
    contracts: [] as Array<ContractDTO>,

    previewLines: [] as Array<InvoicePreviewItemDTO>,
    approvalLines: [] as Array<NeedsApprovalItemDTO>,
    timesheetCheckLines: [] as TimesheetCheckDTO[],
  },
  reducers: {
    setClient(state, action) {
      state.client = action.payload;
      state.previewLines = [];
      state.clientId = state.client?.clientId ?? 0;
      state.contractId = 0;
    },
    setSelctedContract(state, action) {
      state.contractId = action.payload;
    },
    setClientContracts(state, action) {
      state.contracts = action.payload;
    },
    setClientApprovalItems(state, action) {
      state.approvalLines = action.payload;
    },
    setTimesheetCheckItems(state, action) {
      state.timesheetCheckLines = action.payload;
      state.approvalLines = [];
      state.previewLines = [];
    },
    setClientPreviewInvoice(state, action) {
      state.previewLines = action.payload;
      state.timesheetCheckLines = [];
      state.approvalLines = [];
    },
    setShowCreateInvoice(state, action) {
      state.showCreateInvoice = action.payload;
    },
    setShowCreateContract(state, action) {
      state.showCreateContract = action.payload;
    },
    setShowProfilePicEdit(state, action) {
      state.showProfilePicEdit = action.payload;
    },
  },
});

export const clientActions = {
  ...clientSlice.actions,
  loadClient,
  loadContracts,
  createInvoice,
  previewInvoice,
  verifyInvoice,
  createContract,
  terminateContract,
  saveProfilePic,
};

const extra = {};

export type ClientContextType = ReturnType<typeof clientSlice.getInitialState> &
  typeof extra &
  Partial<ContextActions<typeof clientActions>>;

export const ClientContextValue = {
  ...clientSlice.getInitialState(),
  ...extra,
};
