import { Box, styled } from '@mui/material';

export const StickyBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(6.875),
  zIndex: 2,
  [theme.breakpoints.up('sm')]: {
    top: theme.spacing(7.875),
  },
  backdropFilter: 'blur(6px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}));
