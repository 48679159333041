import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../hooks';
import { authActions } from '../../store';
import { useLocation } from 'react-router';

export function LoginGoogle() {
  const ref = useRef<any>(0);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const retUrl = (location.state as any)?.from?.pathname ?? '';

  const onLogin = useCallback(
    (id: string) => {
      dispatch(authActions.loginGoogle(id, retUrl));
    },
    [dispatch, retUrl]
  );

  useEffect(() => {
    const func = () => {
      const google = (window as any).google;
      if (google && google.accounts) {
        google.accounts.id.initialize({
          client_id: `${process.env.REACT_APP_ACC_GOOGLE_CLIENT_ID}`,
          callback: (response: any) => {
            onLogin(response.credential);
          },
        });

        google.accounts.id.renderButton(document.getElementById('signInDiv'), {
          theme: 'outline',
          size: 'large',
        });
      } else {
        if (ref.current) clearTimeout(ref.current);
        ref.current = setTimeout(() => {
          func();
        }, 1000);
      }
    };
    func();
    return () => {
      if (ref.current) clearTimeout(ref.current);
    };
  }, [onLogin]);

  return <div id='signInDiv'></div>;
}
